<template>
    <Page>
        <h2>Deklinationsmuster</h2>
        <p>
            Die Folgenden sind Vorschläge für verschiedene Formen von geschlechtsneutralen Substantiven.
        </p>
        <ul class="list-group mt-4">
            <li
                v-for="({ declension, extendedDeclension, info = null, warning = null }, name) in declensionPatterns"
                :id="name"
                :key="name"
                class="list-group-item"
            >
                <h4>{{ name }}</h4>
                <div v-if="info" class="alert alert-info small">
                    <Icon v="info-circle" />
                    <LinkedText :text="info" />
                </div>
                <div v-if="warning" class="alert alert-warning small">
                    <Icon v="exclamation-triangle" />
                    <LinkedText :text="warning" />
                </div>
                <div class="row">
                    <div v-if="declension" class="col-12 col-md-4">
                        <div class="mb-3">
                            <h5 class="h6">
                                ⋅ <T>nouns.singular</T>
                            </h5>
                            <Declension word="" :template="declension" open />
                        </div>
                        <div v-if="Object.keys(declension.plural).length > 0" class="mb-3">
                            <h5 class="h6">
                                ⁖ <T>nouns.plural</T>
                            </h5>
                            <Declension word="" :template="declension" open plural />
                        </div>
                    </div>
                    <div v-if="extendedDeclension" class="col-12 col-md-8">
                        <div class="mb-3">
                            <h5 class="h6">
                                ⋅ <T>nouns.singular</T>
                            </h5>
                            <Declension word="" :template="extendedDeclension" open />
                        </div>
                        <div class="mb-3">
                            <h5 class="h6">
                                ⁖ <T>nouns.plural</T>
                            </h5>
                            <Declension word="" :template="extendedDeclension" open plural />
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </Page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import declensionPatterns from './declensionPatterns.ts';

export default defineComponent({
    data() {
        return {
            declensionPatterns,
        };
    },
});
</script>
